var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Honor",style:({ height: _vm.window.screen.height + 'px' })},[_vm._m(0),_c('div',{staticClass:"backgroundClass"},[_c('div',{staticClass:"honorOne"},[_vm._m(1),_c('div',{staticClass:"textBox"},_vm._l((_vm.textData),function(item,index){return _c('div',{key:index,staticClass:"titleAndText"},[_c('div',{staticClass:"honorTitleOne"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"honorTextOne"},[_vm._v(_vm._s(item.value))])])}),0),_c('div',{staticClass:"imgBox"},_vm._l((_vm.brandCulture),function(item,index){return _c('div',{key:index,staticStyle:{"margin-right":"1px"},style:({
            width:
              (_vm.window.screen.width - _vm.window.screen.width * 0.08) / 4 -
              1 +
              'px',
            marginTop: index > 3 ? '0.3rem' : '',
          })},[_c('div',{staticClass:"img"},[_c('img',{staticClass:"img",attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"imgTextBox",style:({
              width:
                (_vm.window.screen.width - _vm.window.screen.width * 0.08) / 4 -
                10.1 +
                'px',
            })},[_c('div',{staticClass:"imgTitle"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"imgText"},[_vm._v(" "+_vm._s(item.text)+" ")])])])}),0)]),_c('div',{staticClass:"honorTwo"},[_vm._m(2),_c('div',{staticClass:"imgBoxTwo"},_vm._l((_vm.brandInformation),function(item,index){return _c('div',{key:index,staticStyle:{"margin-right":"1px"},style:({
            marginRight: index == 1 ? '1rem' : '',
            marginLeft: index == 1 ? '1rem' : '',
          })},[_c('div',{staticClass:"imgTwo"},[_c('img',{staticClass:"imgTwo",attrs:{"src":item.url}})]),_c('div',{staticClass:"imgTextBoxTwo",style:({ background: item.color })},[_c('div',{staticClass:"imgTitleTwo"},[_vm._v(_vm._s(item.title)+_vm._s(item.data))]),_c('div',{staticClass:"imgTextTWo"},[_vm._v(" "+_vm._s(item.text)+" ")])])])}),0)]),_c('div',{staticClass:"honorThree"},[_vm._m(3),_c('div',{staticClass:"bigImgBox"},[_c('div',_vm._l((_vm.brandHonor1),function(item,index){return _c('div',{key:index,staticClass:"item",on:{"click":function($event){return _vm.tipShow(index)}}},[_c('div',{staticClass:"imgBoxThree"},[_c('div',[_c('img',{staticClass:"imgThree",attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"TextThree"},[_vm._v(" "+_vm._s(item.text)+" ")])]),(item.istip)?_c('div',{staticClass:"tipBoxLeft"},[_c('div',{staticClass:"tipTitle"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"tipText"},[_vm._v(" "+_vm._s(item.tip)+" ")])]):_vm._e()])}),0),_c('div',_vm._l((_vm.brandHonor2),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"imgBoxThree",on:{"click":function($event){return _vm.tipShow1(index)}}},[_c('div',[_c('img',{staticClass:"imgThree",attrs:{"src":item.url,"alt":""}})]),_c('div',{staticClass:"TextThree"},[_vm._v(" "+_vm._s(item.text)+" ")])]),(item.istip)?_c('div',{staticClass:"tipBoxRight"},[_c('div',{staticClass:"tipTitle"},[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"tipText"},[_vm._v(" "+_vm._s(item.tip)+" ")])]):_vm._e()])}),0)])]),_c('div',{staticClass:"foot"},_vm._l((_vm.bottomList),function(item,index){return _c('div',{key:index,staticStyle:{"align-items":"center"}},[_c('img',{staticStyle:{"width":"1.25rem","height":"1.25rem"},attrs:{"alt":"Vue logo","src":item.url}}),_c('br'),_c('div',{staticClass:"textStyle"},[_vm._v(" "+_vm._s(item.text)+" ")])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"8.4vh","background":"rgb(52, 126, 240)","display":"flex"}},[_c('div',{staticStyle:{"height":"28px","border":"0.1px solid rgb(255, 255, 255)","position":"absolute","left":"8rem","top":"1.1rem","opacity":"0.6"}}),_c('div',{staticClass:"navText"},[_vm._v("公司荣誉")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mask"},[_c('div',{staticClass:"honorTitle"},[_vm._v("品牌文化")]),_c('div',{staticClass:"honorText"},[_vm._v("BRAND CULTURE")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mask"},[_c('div',{staticClass:"honorTitle"},[_vm._v("品牌资讯")]),_c('div',{staticClass:"honorText"},[_vm._v("BRAND INFORMATION")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mask",staticStyle:{"background":"#cadaef"}},[_c('div',{staticClass:"honorTitle",staticStyle:{"color":"#333333"}},[_vm._v("品牌荣誉")]),_c('div',{staticClass:"honorText",staticStyle:{"color":"#333333"}},[_vm._v("BRAND HONOR")])])}]

export { render, staticRenderFns }